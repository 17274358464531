/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateBoatDto { 
    /**
     * The boat name, must be unique
     */
    name?: string;
    /**
     * Boat current timezone.
     */
    timezone?: string;
    /**
     * The boat brand
     */
    brand?: string;
    /**
     * The boat model
     */
    model?: string;
    /**
     * The boat type
     */
    kind?: UpdateBoatDto.KindEnum;
    /**
     * The boat length in meters
     */
    length?: number;
    /**
     * Avoid create boat from an exisitng one
     */
    avoidDefaultFork?: boolean;
    /**
     * Custom boat owner
     */
    ownerUserId?: string;
}
export namespace UpdateBoatDto {
    export type KindEnum = 'MOTOR_YACHT' | 'SAILING';
    export const KindEnum = {
        MotorYacht: 'MOTOR_YACHT' as KindEnum,
        Sailing: 'SAILING' as KindEnum
    };
}


