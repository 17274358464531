/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateTriggerDto { 
    /**
     * Next trigger execution.  For the date-based triggers, nextExecution value will be considered only if dtstart attribute is not included inside the rrule in the recurrence field. To define one-time execution triggers, define a nextExecution without recurrence.
     */
    nextExecution?: number | string;
    /**
     * Trigger execution recurrence. For date-based triggers the rrule supports only UTC timezone. If the rrule contains a DTSTART value, it will be setted to the start of the date at UTC. DTSTART:20210218T094100Z will be converted to DTSTART:20210218T000000Z.
     */
    recurrence?: number | string;
    /**
     * Meter ID. Required only for trigger with type == METER.
     */
    meter?: string;
    /**
     * Trigger type.
     */
    type: CreateTriggerDto.TypeEnum;
}
export namespace CreateTriggerDto {
    export type TypeEnum = 'DATE' | 'METER';
    export const TypeEnum = {
        Date: 'DATE' as TypeEnum,
        Meter: 'METER' as TypeEnum
    };
}


