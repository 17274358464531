/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TaskParentDto { 
    /**
     * ID of the entity that generates the task.
     */
    id: string;
    /**
     * Entity type
     */
    relatedTo: TaskParentDto.RelatedToEnum;
}
export namespace TaskParentDto {
    export type RelatedToEnum = 'AUTH' | 'BOAT' | 'COMPONENT' | 'TASK' | 'EXECUTION' | 'UPM' | 'TRIGGER' | 'RESOURCE' | 'METER' | 'EMAIL' | 'USER' | 'CRON';
    export const RelatedToEnum = {
        Auth: 'AUTH' as RelatedToEnum,
        Boat: 'BOAT' as RelatedToEnum,
        Component: 'COMPONENT' as RelatedToEnum,
        Task: 'TASK' as RelatedToEnum,
        Execution: 'EXECUTION' as RelatedToEnum,
        Upm: 'UPM' as RelatedToEnum,
        Trigger: 'TRIGGER' as RelatedToEnum,
        Resource: 'RESOURCE' as RelatedToEnum,
        Meter: 'METER' as RelatedToEnum,
        Email: 'EMAIL' as RelatedToEnum,
        User: 'USER' as RelatedToEnum,
        Cron: 'CRON' as RelatedToEnum
    };
}


