import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AnimationConfigWithData, AnimationConfigWithPath } from "lottie-web";

@Component({
  selector: "app-empty-animation",
  templateUrl: "./empty-animation.component.html",
  styleUrls: ["./empty-animation.component.scss"],
})
export class EmptyAnimationComponent {
  @Input() public animationWidth: string;
  @Input() public animationHeight: string;
  @Input() public text: string;
  @Input() public options: AnimationConfigWithPath | AnimationConfigWithData;
  @Input() public classModifier?: string;
  @Input() public button?: string;

  @Output() public clickEvent: EventEmitter<void> = new EventEmitter();
}
