/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateUserDto { 
    /**
     * User email address. The new email addresses are not registered immediately. The user will receive a confirmation link to the new email, after confirming, the new address will replace the existing one.
     */
    email?: string;
    /**
     * User full name.
     */
    name?: string;
    /**
     * User preferred language.
     */
    language?: UpdateUserDto.LanguageEnum;
}
export namespace UpdateUserDto {
    export type LanguageEnum = 'en' | 'en-US' | 'en-NZ' | 'es';
    export const LanguageEnum = {
        En: 'en' as LanguageEnum,
        EnUs: 'en-US' as LanguageEnum,
        EnNz: 'en-NZ' as LanguageEnum,
        Es: 'es' as LanguageEnum
    };
}


