/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { selectHelpSlidersEnum } from "src/app/constants";
import { RootState } from "src/app/store";
import * as AppActions from "src/app/store/app/actions";

@Component({
  selector: "app-help-sliders",
  templateUrl: "help-sliders.page.html",
  styleUrls: ["help-sliders.page.scss"],
})
export class HelpSliderPage {
  @Input("type") public slidesToShow: selectHelpSlidersEnum;
  @ViewChild("slider") private slider: IonSlides;
  public slideContents: {
    [type: string]: {
      title: string;
      description: string;
      imageSrc: string;
      button?: { text: string; handler: () => void };
    }[];
  } = {
    [selectHelpSlidersEnum.introduction]: [
      {
        title: "HELP_SLIDER.introduction.first.title",
        description: "HELP_SLIDER.introduction.first.description",
        imageSrc: "assets/help-sliders/introduction/planning.png",
      },
      {
        title: "HELP_SLIDER.introduction.second.title",
        description: "HELP_SLIDER.introduction.second.description",
        imageSrc: "assets/help-sliders/introduction/upm.png",
      },
      {
        title: "HELP_SLIDER.introduction.third.title",
        description: "HELP_SLIDER.introduction.third.description",
        imageSrc: "assets/help-sliders/introduction/history.png",
      },
      {
        title: "HELP_SLIDER.introduction.fourth.title",
        description: "HELP_SLIDER.introduction.fourth.description",
        imageSrc: "assets/help-sliders/introduction/files.png",
        button: {
          text: "HELP_SLIDER.introduction.fourth.buttonText",
          handler: () => this.dismissModal(),
        },
      },
    ],
    [selectHelpSlidersEnum.component]: [
      {
        title: "HELP_SLIDER.component.first.title",
        description: "HELP_SLIDER.component.first.description",
        imageSrc: "assets/help-sliders/component/components.svg",
      },
      {
        title: "HELP_SLIDER.component.second.title",
        description: "HELP_SLIDER.component.second.description",
        imageSrc: "assets/help-sliders/component/files.svg",
      },
      {
        title: "HELP_SLIDER.component.third.title",
        description: "HELP_SLIDER.component.third.description",
        imageSrc: "assets/help-sliders/component/planned-tasks.svg",
        button: {
          text: "HELP_SLIDER.component.third.buttonText",
          handler: () => this.dismissModal(),
        },
      },
    ],
    [selectHelpSlidersEnum.plannedTask]: [
      {
        title: "HELP_SLIDER.plannedTask.first.title",
        description: "HELP_SLIDER.plannedTask.first.description",
        imageSrc: "assets/help-sliders/planned-task/description.png",
      },
      {
        title: "HELP_SLIDER.plannedTask.second.title",
        description: "HELP_SLIDER.plannedTask.second.description",
        imageSrc: "assets/help-sliders/planned-task/priority.png",
      },
      {
        title: "HELP_SLIDER.plannedTask.third.title",
        description: "HELP_SLIDER.plannedTask.third.description",
        imageSrc: "assets/help-sliders/planned-task/chose-trigger.png",
      },
      {
        title: "HELP_SLIDER.plannedTask.fourth.title",
        description: "HELP_SLIDER.plannedTask.fourth.description",
        imageSrc: "assets/help-sliders/planned-task/recurrency.png",
        button: {
          text: "HELP_SLIDER.plannedTask.fourth.buttonText",
          handler: () => this.dismissModal(),
        },
      },
    ],
  };
  public currentSlide: number;

  constructor(private store: Store<RootState>, private modalControler: ModalController) {}

  public async ionViewWillEnter(): Promise<void> {
    this.currentSlide = 0;
    this.slider.options = {
      freeMode: false,
      pager: true,
    };
  }

  public async ionViewWillLeave(): Promise<void> {
    this.store.dispatch(AppActions.setHelpSliders({ helpSlides: [this.slidesToShow] }));
  }

  public async dismissModal(): Promise<void> {
    try {
      const modal = (await this.modalControler.getTop())?.dismiss();
    } catch (e) {}
  }

  public async handleSlideChange(): Promise<void> {
    this.currentSlide = await this.slider.getActiveIndex();
  }

  public nextSlide(): void {
    this.slider.slideNext();
  }
}
