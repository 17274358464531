/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdatePlannedTaskDto { 
    /**
     * Task title.
     */
    title: string;
    /**
     * Task description.
     */
    description: string;
    /**
     * Task priority.
     */
    priority: UpdatePlannedTaskDto.PriorityEnum;
}
export namespace UpdatePlannedTaskDto {
    export type PriorityEnum = 'CRITICAL' | 'HIGH' | 'MODERATE';
    export const PriorityEnum = {
        Critical: 'CRITICAL' as PriorityEnum,
        High: 'HIGH' as PriorityEnum,
        Moderate: 'MODERATE' as PriorityEnum
    };
}


