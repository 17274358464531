import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ActionReducer, MetaReducer, Store, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { environment as environmentDeploy } from "src/environments/environment.deploy";
import { EffectsModule } from "@ngrx/effects";
import { localStorageSync } from "ngrx-store-localstorage";
import { reducers, rootEffects, RootState } from "./store";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { createTranslateLoader } from "./services/i18n/translate-loader";
import { DEFAULT_LANGUAGE } from "src/app/constants";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SentryService } from "./services/sentry/sentry.service";
import * as Sentry from "@sentry/angular";
import { ApiModule, Configuration } from "./services/api";
import { ServiceWorkerModule } from "@angular/service-worker";
import { initApplication } from "./services/init/init-application";
import { logoutMetaReducer } from "./store/user/meta-reducer";
import { SwUpdateService } from "./services/sw-updater/sw-updater.service";
import { LoaderInterceptor } from "./interceptors/loader/loader.interceptor";
import { AuthInterceptor } from "./interceptors/auth/auth.interceptor";
import { OfflineIndicatorComponentModule } from "./components/offline-indicator/offline-indicator.module";
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { EnvService } from "./services/env/env.service";
import { AngularFireModule } from "@angular/fire";
import { APP_NAME, APP_VERSION, ScreenTrackingService, AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { persistedKeys as app } from "./store/app/state";
import { persistedKeys as boat } from "./store/boat/state";
import { persistedKeys as component } from "./store/component/state";
import { persistedKeys as meter } from "./store/meter/state";
import { persistedKeys as periodicTask } from "./store/periodic-task/state";
import { persistedKeys as resource } from "./store/resource/state";
import { persistedKeys as taskEvent } from "./store/task-event/state";
import { persistedKeys as user } from "./store/user/state";
import { persistedKeys as taskNote } from "./store/task-note/state";
import { ResolveResourcePipe } from "./pipes/resource/resolve-resource.pipe";
import { ResolveResourcePipeModule } from "./pipes/resource/resolve-resource.module";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { MultipleDocumentsPicker } from "@ionic-native/multiple-document-picker/ngx";
import { DatePipe } from "@angular/common";
import { SideMenuModule } from "./components/side-menu/side-menu.module";
import { EmptyAnimationComponentModule } from "./components/empty-animation/empty-animation.module";
import { CreateBoatPagePageModule } from "./pages/create-boat/create-boat.module";
import { HelpSliderPageModule } from "./modals/help-sliders/help-sliders.module";
import { SuccessPageModule } from "./modals/success/success.module";

export function localStorageReduxSync(reducer: ActionReducer<RootState>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { app },
      { boat },
      "calendar",
      { component },
      "filter",
      { meter },
      { periodicTask },
      { resource },
      { taskEvent },
      { user },
      { taskNote },
    ],
    rehydrate: true,
  })(reducer);
}

export function setApiConfig(): Configuration {
  const env = new EnvService();
  return new Configuration({ basePath: env.getVariable("api") });
}

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync, logoutMetaReducer];

const globalImports = [
  EmptyAnimationComponentModule,
  HelpSliderPageModule,
  CreateBoatPagePageModule,
  ResolveResourcePipeModule,
  SuccessPageModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({ mode: "ios" }),
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    EffectsModule.forRoot(rootEffects),
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase, "planm8-app"),
    AngularFireAnalyticsModule,
    ApiModule.forRoot(setApiConfig),
    OfflineIndicatorComponentModule,
    SideMenuModule,
    ...globalImports,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    File,
    FileOpener,
    ImagePicker,
    MultipleDocumentsPicker,
    ResolveResourcePipe,
    ScreenTrackingService,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryService },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => translateService.currentLang,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [Sentry.TraceService, Store, SwUpdateService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_NAME, useValue: "planm8-app" },
    { provide: APP_VERSION, useValue: environmentDeploy.release },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
