import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HelpSliderPage } from "./help-sliders.page";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [IonicModule, CommonModule, TranslateModule],
  declarations: [HelpSliderPage],
})
export class HelpSliderPageModule {}
