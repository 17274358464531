/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateComponentDto { 
    /**
     * Component name.
     */
    name: string;
    /**
     * Component name.
     */
    description?: string;
    /**
     * Component manufacturer.
     */
    manufacturer?: string;
    /**
     * Component model.
     */
    model?: string;
    /**
     * Component serial number.
     */
    serialNumber?: string;
    /**
     * Current status of the component. If no status is provided the component will be ACTIVE.
     */
    status?: UpdateComponentDto.StatusEnum;
    /**
     * Yacht system that the component belongs to.
     */
    system: UpdateComponentDto.SystemEnum;
}
export namespace UpdateComponentDto {
    export type StatusEnum = 'ACTIVE' | 'LOCKED' | 'DISABLED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Locked: 'LOCKED' as StatusEnum,
        Disabled: 'DISABLED' as StatusEnum
    };
    export type SystemEnum = 'UNCATEGORIZED' | 'HULL_SUPERSTRUCTURE' | 'MAIN_ENGINES_GEARBOXES' | 'GENERATORS' | 'DRIVELINE' | 'STEERING' | 'HYDRAULICS' | 'FUEL_SYSTEM' | 'FIRE_SYSTEM' | 'HEATING_AC' | 'PNEUMATICS' | 'BILGE_SYSTEM' | 'FRIDGES_FREEZERS' | 'FRESH_WATER' | 'GREY_WATER' | 'BLACK_WATER' | 'VENTILATION' | 'LUBE_WASTE_OIL' | 'ELECTRICAL' | 'DC_ELECTRICAL' | 'AC_ELECTRICAL_APPLIANCES' | 'APPLIANCES' | 'NAVIGATION_COMMUNICATION' | 'ANCHORING_LIFTING_GEAR' | 'DECK_HARDWARE_FITTINGS' | 'SAFETY_LIFE_SAVING_EQUIPMENT' | 'TENDERS_&_TOYS' | 'FIXED_RIGGING' | 'RUNNING_RIGGING' | 'SALLS' | 'MISCELLANEOUS';
    export const SystemEnum = {
        Uncategorized: 'UNCATEGORIZED' as SystemEnum,
        HullSuperstructure: 'HULL_SUPERSTRUCTURE' as SystemEnum,
        MainEnginesGearboxes: 'MAIN_ENGINES_GEARBOXES' as SystemEnum,
        Generators: 'GENERATORS' as SystemEnum,
        Driveline: 'DRIVELINE' as SystemEnum,
        Steering: 'STEERING' as SystemEnum,
        Hydraulics: 'HYDRAULICS' as SystemEnum,
        FuelSystem: 'FUEL_SYSTEM' as SystemEnum,
        FireSystem: 'FIRE_SYSTEM' as SystemEnum,
        HeatingAc: 'HEATING_AC' as SystemEnum,
        Pneumatics: 'PNEUMATICS' as SystemEnum,
        BilgeSystem: 'BILGE_SYSTEM' as SystemEnum,
        FridgesFreezers: 'FRIDGES_FREEZERS' as SystemEnum,
        FreshWater: 'FRESH_WATER' as SystemEnum,
        GreyWater: 'GREY_WATER' as SystemEnum,
        BlackWater: 'BLACK_WATER' as SystemEnum,
        Ventilation: 'VENTILATION' as SystemEnum,
        LubeWasteOil: 'LUBE_WASTE_OIL' as SystemEnum,
        Electrical: 'ELECTRICAL' as SystemEnum,
        DcElectrical: 'DC_ELECTRICAL' as SystemEnum,
        AcElectricalAppliances: 'AC_ELECTRICAL_APPLIANCES' as SystemEnum,
        Appliances: 'APPLIANCES' as SystemEnum,
        NavigationCommunication: 'NAVIGATION_COMMUNICATION' as SystemEnum,
        AnchoringLiftingGear: 'ANCHORING_LIFTING_GEAR' as SystemEnum,
        DeckHardwareFittings: 'DECK_HARDWARE_FITTINGS' as SystemEnum,
        SafetyLifeSavingEquipment: 'SAFETY_LIFE_SAVING_EQUIPMENT' as SystemEnum,
        TendersToys: 'TENDERS_&_TOYS' as SystemEnum,
        FixedRigging: 'FIXED_RIGGING' as SystemEnum,
        RunningRigging: 'RUNNING_RIGGING' as SystemEnum,
        Salls: 'SALLS' as SystemEnum,
        Miscellaneous: 'MISCELLANEOUS' as SystemEnum
    };
}


