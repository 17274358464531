<ion-header [translucent]="false">
  <ion-toolbar class="--no-border">
    <ion-title [innerHTML]="'SWITCH_YACHT.title' | translate"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalController.dismiss()" [innerHTML]="'SWITCH_YACHT.dismiss' | translate"></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="main-content" [fullscreen]="true" [scrollY]="!searchFocus || searchTerm.length">
  <ion-header class="ion-no-border" collapse="condense" [translucent]="false">
    <ion-toolbar>
      <ion-title size="large" innerHTML="{{ 'SWITCH_YACHT.title' | translate }}"></ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-header class="sticky" [translucent]="false">
    <ion-toolbar class="--padding">
      <ion-searchbar
        showCancelButton="focus"
        [cancelButtonText]="'SWITCH_YACHT.cancel' | translate"
        enterkeyhint="search"
        [value]="searchTerm"
        (ionChange)="updateSearchTerm($event.target.value)"
        (ionBlur)="updateFocus(false)"
        (ionFocus)="updateFocus(true)"
        class="main-searchbar"
      ></ion-searchbar>
    </ion-toolbar>
  </ion-header>
  <app-boat-list *ngIf="!searchTerm.length" [boats]="boats" (selectBoat)="selectBoat($event)" [class.--veil]="searchFocus"></app-boat-list>
  <app-boat-list-search
    *ngIf="searchTerm.length"
    [boats]="boats"
    [searchTerm]="searchTerm"
    (selectBoat)="selectBoat($event)"
  ></app-boat-list-search>
</ion-content>
