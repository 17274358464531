<ion-header class="create-boat__header ion-no-border" [translucent]="false">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="end">
      <ion-button *ngIf="(boatId$ | async)" (click)="dismissModal()" [innerHTML]="'CREATE_BOAT.dismiss' | translate"> </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="create-boat__content">
  <div class="create-boat__content__form-wrapper">
    <form
      *ngIf="!(success$ | async)"
      class="create-boat__content__form-wrapper__form"
      [formGroup]="boatForm"
      (keyup.enter)="boatForm.valid && createBoat()"
    >
      <ion-title class="big" [innerHTML]="'CREATE_BOAT.newBoat' | translate"> </ion-title>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.name' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="text" formControlName="name" enterkeyhint="next"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.kind' | translate"></ion-label>
        <ion-select required="true" enterkeyhint="next" formControlName="kind" [placeholder]="'CREATE_BOAT.kindPlaceholder' | translate">
          <ion-select-option *ngFor="let kind of kindEnum" [value]="kind" [innerHTML]="'CREATE_BOAT.' + kind | translate">
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.brand' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="text" formControlName="brand" enterkeyhint="next"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.model' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="text" formControlName="model" enterkeyhint="next"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.length' | translate"></ion-label>
        <ion-input
          [min]="5"
          [max]="100"
          required="true"
          class="input"
          type="number"
          inputmode="numeric"
          formControlName="length"
          enterkeyhint="send"
          (ionChange)="fetchPricing($event)"
        >
        </ion-input>
      </ion-item>

      <div class="create-boat__content__pricings">
        <ion-title class="small" [innerHTML]="'FORK_BOAT.chooseSubscription' | translate"> </ion-title>
        <ion-spinner class="create-boat__content__pricings__spinner" *ngIf="(loading$ | async)"></ion-spinner>
        <span
          class="create-boat__content__pricings__empty-message"
          *ngIf="(availableSubscriptions$ | async).length === 0 && !(loading$ | async)"
          [innerHTML]="'FORK_BOAT.chooseLenght' | translate"
        ></span>
        <app-pricing-card
          *ngFor="let subscription of availableSubscriptions$ | async"
          [subscription]="subscription"
          [isSelected]="subscription.priceId === boatForm.value.priceId"
          (selectSubscription)="selectSubscription($event.priceId)"
        ></app-pricing-card>

        <a class="create-boat__content__pricings__link" (click)="openPricing()">
          {{ 'CREATE_BOAT.moreInfo' | translate }}
          <ion-icon name="open-outline"></ion-icon>
        </a>
      </div>
      <ion-button
        class="create-boat__content__form-wrapper__button"
        [disabled]="boatForm.invalid || (loading$ | async)"
        [innerHTML]="'CREATE_BOAT.createButton' | translate"
        (click)="createBoat()"
      >
      </ion-button>
      <ion-button
        *ngIf="(boatId$ | async)"
        class="create-boat__content__form-wrapper__button --text"
        [routerLink]="['/']"
        routerDirection="forward"
        [innerHTML]="'CREATE_BOAT.skipForm' | translate"
      >
      </ion-button>
    </form>
  </div>

  <div *ngIf="(success$ | async)" class="create-boat__content__success">
    <app-empty-animation
      class="create-boat__content__success__lottie"
      [options]="{ path: '/assets/lotties/success.json', loop: 0 }"
      animationWidth="230px"
      animationHeight="230px"
    >
    </app-empty-animation>
    <span class="create-boat__content__success__text" [innerHTML]="'CREATE_BOAT.messageSuccessAlert' | translate"></span>
    <ion-button
      class="create-boat__content__success__button"
      [innerHTML]="'CREATE_BOAT.buttonSuccessAlert' | translate"
      (click)="dismissModal()"
    ></ion-button>
  </div>
</ion-content>
