<ion-header class="fork-boat__header ion-no-border" [translucent]="false">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" [innerHTML]="'FORK_BOAT.dismiss' | translate"> </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="fork-boat__content">
  <div class="fork-boat__content__form-wrapper">
    <form
      *ngIf="!(success$ | async)"
      class="fork-boat__content__form-wrapper__form"
      [formGroup]="boatForm"
      (keyup.enter)="boatForm.valid && forkBoat()"
    >
      <ion-title class="big" [innerHTML]="'FORK_BOAT.newBoat' | translate: { boatName: boatToFork?.name }"> </ion-title>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.name' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="text" formControlName="name" enterkeyhint="next"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.kind' | translate"></ion-label>
        <ion-select required="true" enterkeyhint="next" formControlName="kind" [placeholder]="'FORK_BOAT.kindPlaceholder' | translate">
          <ion-select-option *ngFor="let kind of kindEnum" [value]="kind" [innerHTML]="'FORK_BOAT.' + kind | translate">
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.brand' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="text" formControlName="brand" enterkeyhint="next"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.model' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="text" formControlName="model" enterkeyhint="next"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.excludeResources' | translate"></ion-label>
        <ion-select
          required="true"
          multiple="true"
          enterkeyhint="next"
          formControlName="excludeResources"
          [placeholder]="'FORK_BOAT.excludePlaceholder' | translate"
        >
          <ion-select-option
            *ngFor="let resource of excludeEnum"
            [value]="resource"
            [innerHTML]="'FORK_BOAT.resource.' + resource | translate"
          >
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-title class="small" [innerHTML]="'FORK_BOAT.subscriptions' | translate"> </ion-title>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.length' | translate"></ion-label>
        <ion-input
          [min]="5"
          [max]="100"
          required="true"
          class="input"
          type="number"
          inputmode="numeric"
          formControlName="length"
          enterkeyhint="send"
          debounce="500"
          (ionChange)="fetchPricing($event)"
        >
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.ownerId' | translate"></ion-label>
        <ion-input required="true" class="input" type="text" inputmode="numeric" formControlName="ownerUserId" enterkeyhint="next">
        </ion-input>
      </ion-item>
      <div class="fork-boat__content__pricings">
        <ion-title class="small" [innerHTML]="'FORK_BOAT.chooseSubscription' | translate"> </ion-title>
        <ion-spinner class="fork-boat__content__pricings__spinner" *ngIf="(loading$ | async)"></ion-spinner>
        <span
          class="fork-boat__content__pricings__empty-message"
          *ngIf="(availableSubscriptions$ | async).length === 0 && !(loading$ | async)"
          [innerHTML]="'FORK_BOAT.chooseLenght' | translate"
        ></span>
        <app-pricing-card
          *ngFor="let subscription of availableSubscriptions$ | async"
          [subscription]="subscription"
          [isSelected]="subscription.priceId === boatForm.value.priceId"
          (selectSubscription)="selectSubscription($event.priceId)"
        ></app-pricing-card>
      </div>

      <ion-button
        class="fork-boat__content__form-wrapper__button"
        [disabled]="boatForm.invalid || (loading$ | async)"
        [innerHTML]="'FORK_BOAT.createButton' | translate"
        (click)="forkBoat()"
      >
      </ion-button>
    </form>
  </div>

  <div *ngIf="(success$ | async)" class="fork-boat__content__success">
    <app-empty-animation
      class="fork-boat__content__success__lottie"
      [options]="{ path: '/assets/lotties/success.json', loop: 0 }"
      animationWidth="230px"
      animationHeight="230px"
    >
    </app-empty-animation>
    <span class="fork-boat__content__success__text" [innerHTML]="'FORK_BOAT.messageSuccessAlert' | translate"></span>
    <ion-button
      class="fork-boat__content__success__button"
      [innerHTML]="'FORK_BOAT.buttonSuccessAlert' | translate"
      (click)="dismissModal()"
    ></ion-button>
  </div>
</ion-content>
