/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComponentPayloadDto } from './component-payload-dto';


export interface ComponentEventDto { 
    /**
     * Event metadata
     */
    metadata?: ComponentPayloadDto;
    /**
     * Event ID.
     */
    _id?: string;
    /**
     * Event type.
     */
    eventType: ComponentEventDto.EventTypeEnum;
    /**
     * Event creation date.
     */
    createdAt: string;
    payload: ComponentPayloadDto;
}
export namespace ComponentEventDto {
    export type EventTypeEnum = 'COMPONENT_CREATED' | 'COMPONENT_FORKED' | 'COMPONENT_UPDATED' | 'COMPONENT_DELETED';
    export const EventTypeEnum = {
        Created: 'COMPONENT_CREATED' as EventTypeEnum,
        Forked: 'COMPONENT_FORKED' as EventTypeEnum,
        Updated: 'COMPONENT_UPDATED' as EventTypeEnum,
        Deleted: 'COMPONENT_DELETED' as EventTypeEnum
    };
}


