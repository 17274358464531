import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { EmptyAnimationComponent } from "./empty-animation.component";
import { LottieModule } from "ngx-lottie";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LottieModule.forRoot({ player: () => import(/* webpackChunkName: 'lottie-web' */ "lottie-web/build/player/lottie_light") }),
  ],
  declarations: [EmptyAnimationComponent],
  exports: [EmptyAnimationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EmptyAnimationComponentModule {}
