/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateResourceDto { 
    /**
     * (Optional) An object with no defined structure that allows you to link metadata to this resource
     */
    metadata?: object;
    /**
     * (Optional) This is the resource name, use this if you want to display the name when requesting the resource
     */
    name?: string;
    /**
     * The type for the uploaded resource
     */
    resourceType?: UpdateResourceDto.ResourceTypeEnum;
    /**
     * (Optional) The entity id to link this resource to
     */
    referenceId?: string;
    /**
     * (Optional) The type for the linked entity
     */
    referenceType?: UpdateResourceDto.ReferenceTypeEnum;
    /**
     * A list containing one or multiple tags to be able to filter this resource
     */
    tags?: Array<UpdateResourceDto.TagsEnum>;
}
export namespace UpdateResourceDto {
    export type ResourceTypeEnum = 'IMAGE' | 'FILE' | 'URL' | 'SERIALNO';
    export const ResourceTypeEnum = {
        Image: 'IMAGE' as ResourceTypeEnum,
        File: 'FILE' as ResourceTypeEnum,
        Url: 'URL' as ResourceTypeEnum,
        Serialno: 'SERIALNO' as ResourceTypeEnum
    };
    export type ReferenceTypeEnum = 'AUTH' | 'BOAT' | 'COMPONENT' | 'TASK' | 'EXECUTION' | 'UPM' | 'TRIGGER' | 'RESOURCE' | 'METER' | 'EMAIL' | 'USER' | 'CRON';
    export const ReferenceTypeEnum = {
        Auth: 'AUTH' as ReferenceTypeEnum,
        Boat: 'BOAT' as ReferenceTypeEnum,
        Component: 'COMPONENT' as ReferenceTypeEnum,
        Task: 'TASK' as ReferenceTypeEnum,
        Execution: 'EXECUTION' as ReferenceTypeEnum,
        Upm: 'UPM' as ReferenceTypeEnum,
        Trigger: 'TRIGGER' as ReferenceTypeEnum,
        Resource: 'RESOURCE' as ReferenceTypeEnum,
        Meter: 'METER' as ReferenceTypeEnum,
        Email: 'EMAIL' as ReferenceTypeEnum,
        User: 'USER' as ReferenceTypeEnum,
        Cron: 'CRON' as ReferenceTypeEnum
    };
    export type TagsEnum = 'LOGO' | 'LOCATION' | 'ATTACHMENT' | 'ASYNC';
    export const TagsEnum = {
        Logo: 'LOGO' as TagsEnum,
        Location: 'LOCATION' as TagsEnum,
        Attachment: 'ATTACHMENT' as TagsEnum,
        Async: 'ASYNC' as TagsEnum
    };
}


