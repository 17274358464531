import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { SharedRoutes } from "./constants";
import { LoginGuard } from "./guards/login.guard";

const routes: Routes = [
  {
    path: SharedRoutes.login,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
    data: { hideSideMenu: true },
  },
  {
    path: SharedRoutes.forgotPassword,
    loadChildren: () => import("./pages/forgot-password/forgot-password.module").then(m => m.ForgotPasswordPageModule),
    data: { hideSideMenu: true },
  },
  {
    path: "",
    loadChildren: () => import("./tabs/tabs.module").then(m => m.TabsPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: SharedRoutes.profile,
    loadChildren: () => import("./pages/profile/profile.module").then(m => m.ProfilePageModule),
  },
  {
    path: SharedRoutes.meters,
    loadChildren: () => import("./pages/meters/meters.module").then(m => m.MetersPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.generalConfiguration,
    loadChildren: () => import("./pages/general-configuration/general-configuration.module").then(m => m.GeneralConfigurationPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.offlineConfig,
    loadChildren: () => import("./pages/offline-config/offline-config.module").then(m => m.OfflineConfigPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.subscription,
    loadChildren: () => import("./pages/subscription/subscription.module").then(m => m.SubscriptionPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.report,
    loadChildren: () => import("./pages/report/report.module").then(m => m.ReportPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.generalDocuments,
    loadChildren: () => import("./pages/general-documents/general-documents.module").then(m => m.GeneralDocumentsPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.history,
    loadChildren: () => import("./pages/history/history.module").then(m => m.HistoryPageModule),
    data: { showBackButton: false },
  },
  {
    path: SharedRoutes.userManagement,
    loadChildren: () => import("./pages/user-management/user-management.module").then(m => m.UserManagementPageModule),
    data: { showBackButton: false },
  },
  {
    path: `${SharedRoutes.activateAccount}/:${SharedRoutes.code}`,
    loadChildren: () => import("./pages/activate-account/activate-account.module").then(m => m.ActivateAccountPageModule),
    data: { hideSideMenu: true },
  },
  {
    path: `${SharedRoutes.passwordRecovery}/:${SharedRoutes.code}`,
    loadChildren: () => import("./pages/forgot-password/forgot-password.module").then(m => m.ForgotPasswordPageModule),
    data: { hideSideMenu: true },
  },
  {
    path: `${SharedRoutes.signUp}`,
    loadChildren: () => import("./pages/sign-up/sign-up.module").then(m => m.SignUpPageModule),
    data: { hideSideMenu: true },
  },
  {
    path: `${SharedRoutes.autoLogin}/:${SharedRoutes.refreshToken}`,
    loadChildren: () => import("./pages/auto-login/auto-login.module").then(m => m.AutoLoginPageModule),
    data: { hideSideMenu: true },
  },
  {
    path: SharedRoutes.createBoat,
    loadChildren: () => import("./pages/create-boat/create-boat.module").then(m => m.CreateBoatPagePageModule),
    data: { hideSideMenu: true },
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: SharedRoutes.planning,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
