import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { PlannedTaskService, ResourceDto, ResourceService, TriggerService, UPMService } from "src/app/services/api";
import { RootState } from "..";
import * as PeriodicTaskActions from "./actions";
import * as TaskEventActions from "src/app/store/task-event/actions";
import * as ResourceActions from "src/app/store/resource/actions";
import * as FromBoat from "src/app/store/boat/selectors";
import { ModalController, NavController } from "@ionic/angular";
import { Pm8PeriodicTaskDto, Pm8TriggerDto } from "src/app/store/periodic-task/state";
import { Router } from "@angular/router";
import { ComponentTabs, SharedRoutes } from "src/app/constants";
import { SuccessPage, SuccessQueryParams } from "src/app/modals/success/success.page";
import { PosthogService } from "src/app/services/posthog/posthog.service";

@Injectable()
export class PeriodicTaskEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.loadAll),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([, boatId]) =>
        this.plannedTaskService.plannedTasksControllerFindBoatTasks({ boat: boatId, detailed: true }).pipe(
          map((periodicTasks: Pm8PeriodicTaskDto[]) => PeriodicTaskActions.loadAllSuccess({ periodicTasks })),
          catchError(() => of(PeriodicTaskActions.loadAllFailure())),
        ),
      ),
    ),
  );

  public loadAllByComponent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.loadAllByComponent),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.plannedTaskService.plannedTasksControllerFindAll({ boat: boatId, component: action.componentId, detailed: true }).pipe(
          map((periodicTasks: Pm8PeriodicTaskDto[]) => PeriodicTaskActions.loadAllByComponentSuccess({ periodicTasks })),
          catchError(() => of(PeriodicTaskActions.loadAllByComponentFailure())),
        ),
      ),
    ),
  );

  public loadOneByComponent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.loadOneByComponent),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.plannedTaskService
          .plannedTasksControllerFindOne({ boat: boatId, component: action.componentId, task: action.id, detailed: true })
          .pipe(
            map((periodicTask: Pm8PeriodicTaskDto) => PeriodicTaskActions.loadOneByComponentSuccess({ periodicTask })),
            catchError(() => of(PeriodicTaskActions.loadOneByComponentFailure())),
          ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.create),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.plannedTaskService
          .plannedTasksControllerCreate({
            boat: boatId,
            component: action.componentId,
            createPlannedTaskDto: action.periodicTask,
          })
          .pipe(
            mergeMap((periodicTask: Pm8PeriodicTaskDto) =>
              forkJoin([
                ...action.triggers.map(trigger =>
                  this.triggerService
                    .triggersControllerCreate({
                      boat: boatId,
                      component: action.componentId,
                      task: periodicTask._id,
                      createTriggerDto: {
                        type: trigger.type,
                        meter: trigger.meter?._id,
                        nextExecution: trigger.nextExecution,
                        recurrence: trigger.recurrence,
                      },
                    })
                    .pipe(catchError(() => of(PeriodicTaskActions.createTriggerFailure()))),
                ),
              ]).pipe(
                mergeMap((triggers: Pm8TriggerDto[]) => [
                  PeriodicTaskActions.createSuccess({ periodicTask }),
                  ...triggers.map(trigger =>
                    PeriodicTaskActions.createTriggerSuccess({
                      trigger: { ...trigger, meter: action.triggers.find(t => t.type === trigger.type)?.meter },
                    }),
                  ),
                ]),
                tap(() => this.modalController.dismiss()),
                catchError(() => of(PeriodicTaskActions.createFailure())),
              ),
            ),
          ),
      ),
    ),
  );

  public createSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PeriodicTaskActions.createSuccess),
        tap(({ periodicTask: { type } }) => this.posthogService.sendEvent("createResource", { type: "task", taskType: type })),
      ),
    { dispatch: false },
  );

  public createUnplannedTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.createUnplannedTask),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ componentId, createUpmDto }, boatId]) =>
        this.upmService.upmControllerCreateUpm({ boat: boatId, component: componentId, createUpmDto }).pipe(
          mergeMap(event =>
            this.resourceService
              .resourceControllerUpdateAll({
                boat: boatId,
                reference: componentId,
                tags: [ResourceDto.TagsEnum.Async],
                updateResourceDto: {
                  referenceId: event._id,
                  referenceType: ResourceDto.ReferenceTypeEnum.Upm,
                  tags: [ResourceDto.TagsEnum.Attachment],
                },
              })
              .pipe(
                mergeMap(resources => [
                  ResourceActions.bulkUpdateSuccess({ resources }),
                  PeriodicTaskActions.createUnplannedTaskSuccess({ createUpmDto }),
                  TaskEventActions.loadHistoryByComponent({ componentId }),
                ]),
                tap(() => this.modalController.dismiss()),
                catchError(() => of(PeriodicTaskActions.createUnplannedTaskFailure())),
              ),
          ),
        ),
      ),
    ),
  );

  public createUnplannedTaskSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PeriodicTaskActions.createUnplannedTaskSuccess),
        tap(({ createUpmDto: { type } }) => this.posthogService.sendEvent("createResource", { type: "task", taskType: type })),
      ),
    { dispatch: false },
  );

  public execute$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.execute),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ componentId, id, notes }, boatId]) =>
        this.plannedTaskService
          .plannedTasksControllerExecute({
            boat: boatId,
            component: componentId,
            task: id,
            executeTaskDto: { executionDate: new Date().toISOString(), notes: [notes] },
          })
          .pipe(
            mergeMap(event =>
              this.resourceService
                .resourceControllerUpdateAll({
                  boat: boatId,
                  reference: id,
                  tags: [ResourceDto.TagsEnum.Async],
                  updateResourceDto: {
                    referenceId: event._id,
                    referenceType: ResourceDto.ReferenceTypeEnum.Execution,
                    tags: [ResourceDto.TagsEnum.Attachment],
                  },
                })
                .pipe(
                  mergeMap(resources => {
                    if (this.router.url.includes(SharedRoutes.planning)) {
                      this.nav.navigateBack([SharedRoutes.planning]);
                    } else {
                      this.nav.navigateBack([SharedRoutes.components, componentId, ComponentTabs.upcomingTasks]);
                    }
                    return [ResourceActions.bulkUpdateSuccess({ resources }), PeriodicTaskActions.executeSuccess({ id })];
                  }),
                ),
            ),
            catchError(() => of(PeriodicTaskActions.executeFailure())),
          ),
      ),
    ),
  );

  public executeSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PeriodicTaskActions.executeSuccess),
        tap(() => this.posthogService.sendEvent("executeTask")),
      ),
    { dispatch: false },
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.update),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.plannedTaskService
          .plannedTasksControllerUpdate({
            boat: boatId,
            component: action.componentId,
            task: action.id,
            updatePlannedTaskDto: action.updatePeriodicTaskDto,
          })
          .pipe(
            mergeMap((periodicTask: Pm8PeriodicTaskDto) => {
              if (!action.triggers?.length) {
                this.modalController.dismiss();
                return of(PeriodicTaskActions.updateSuccess({ periodicTask }));
              }
              return forkJoin([
                ...action.triggers.map(({ _id, type, meter, nextExecution, recurrence }) => {
                  if (_id) {
                    return this.triggerService
                      .triggersControllerUpdate({
                        trigger: _id,
                        boat: boatId,
                        component: action.componentId,
                        task: periodicTask._id,
                        updateTriggerDto: { meter: meter?._id, type, nextExecution, recurrence },
                      })
                      .pipe(catchError(() => of(PeriodicTaskActions.createTriggerFailure())));
                  }
                  return this.triggerService
                    .triggersControllerCreate({
                      boat: boatId,
                      task: periodicTask._id,
                      component: action.componentId,
                      createTriggerDto: { meter: meter?._id, type, nextExecution, recurrence },
                    })
                    .pipe(catchError(() => of(PeriodicTaskActions.createTriggerFailure())));
                }),
              ]).pipe(
                mergeMap((triggers: Pm8TriggerDto[]) => [
                  PeriodicTaskActions.updateSuccess({ periodicTask }),
                  ...triggers.map(trigger =>
                    PeriodicTaskActions.updateTriggerSuccess({
                      trigger: { ...trigger, meter: action.triggers.find(({ type }) => type === trigger.type)?.meter },
                    }),
                  ),
                ]),
                tap(() => this.modalController.dismiss()),
                catchError(() => of(PeriodicTaskActions.updateFailure())),
              );
            }),
          ),
      ),
    ),
  );

  public removePeriodicTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.remove),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ id, componentId }, boatId]) =>
        this.plannedTaskService.plannedTasksControllerRemove({ boat: boatId, component: componentId, task: id }).pipe(
          map(() => PeriodicTaskActions.removeSuccess({ id })),
          catchError(() => of(PeriodicTaskActions.removeFailure())),
        ),
      ),
    ),
  );

  public removeTrigger$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.removeTrigger),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ id, periodicTaskId, componentId }, boatId]) =>
        this.triggerService.triggersControllerRemove({ trigger: id, task: periodicTaskId, boat: boatId, component: componentId }).pipe(
          map(() => PeriodicTaskActions.removeTriggerSuccess({ id, periodicTaskId })),
          catchError(() => of(PeriodicTaskActions.createTriggerFailure())),
        ),
      ),
    ),
  );

  public posposePeriodicTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(PeriodicTaskActions.postpone),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ componentId, periodicTaskId, triggerId, postponeTask }, boatId]) =>
        this.plannedTaskService
          .plannedTasksControllerPostpone({
            boat: boatId,
            component: componentId,
            task: periodicTaskId,
            trigger: triggerId,
            postponeTaskDto: postponeTask,
          })
          .pipe(
            map(() => PeriodicTaskActions.postponeSuccess({ componentId })),
            catchError(() => of(PeriodicTaskActions.postponeFailure())),
          ),
      ),
    ),
  );

  public postponeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PeriodicTaskActions.postponeSuccess),
        tap(async ({ componentId }) => {
          const currentModal = await this.modalController.getTop();
          await currentModal.dismiss();

          const componentProps: SuccessQueryParams = {
            headerTitle: "UPCOMING_TASK_POSTPONE.successHeaderTitle",
            buttonText: "UPCOMING_TASK_POSTPONE.successButtonText",
          };

          const modal = await this.modalController.create({
            component: SuccessPage,
            componentProps,
            swipeToClose: true,
            backdropDismiss: true,
            cssClass: ["half-modal"],
          });

          await modal.present();
          await modal.onWillDismiss();

          if (this.router.url.includes(SharedRoutes.planning)) {
            this.nav.navigateBack([SharedRoutes.planning]);
          } else {
            this.nav.navigateBack([SharedRoutes.components, componentId, ComponentTabs.upcomingTasks]);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly plannedTaskService: PlannedTaskService,
    private readonly triggerService: TriggerService,
    private readonly upmService: UPMService,
    private readonly resourceService: ResourceService,
    private readonly modalController: ModalController,
    private readonly nav: NavController,
    private readonly router: Router,
    private readonly posthogService: PosthogService,
  ) {}
}
