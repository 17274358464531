/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BoatUserRoleDto { 
    /**
     * Roles to assign when the user.
     */
    roles: Array<BoatUserRoleDto.RolesEnum>;
}
export namespace BoatUserRoleDto {
    export type RolesEnum = 'OWNER' | 'OPERATOR' | 'VIEWER';
    export const RolesEnum = {
        Owner: 'OWNER' as RolesEnum,
        Operator: 'OPERATOR' as RolesEnum,
        Viewer: 'VIEWER' as RolesEnum
    };
}


