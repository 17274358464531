/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionDto { 
    /**
     * Boat id.
     */
    _id?: string;
    /**
     * Subscription Id from Stripe.
     */
    subscriptionId: string;
    /**
     * Boat length in meters.
     */
    boatLength: number;
    /**
     * Current status of the subscription.
     */
    status: SubscriptionDto.StatusEnum;
    /**
     * Last status update.
     */
    statusStartAt: string;
    /**
     * Current status might end at.
     */
    statusEndAt: string;
}
export namespace SubscriptionDto {
    export type StatusEnum = 'ACTIVE' | 'CANCELED' | 'INCOMPLETE' | 'INCOMPLETE_EXPIRED' | 'PAST_DUE' | 'TRIALING' | 'UNPAID';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Canceled: 'CANCELED' as StatusEnum,
        Incomplete: 'INCOMPLETE' as StatusEnum,
        IncompleteExpired: 'INCOMPLETE_EXPIRED' as StatusEnum,
        PastDue: 'PAST_DUE' as StatusEnum,
        Trialing: 'TRIALING' as StatusEnum,
        Unpaid: 'UNPAID' as StatusEnum
    };
}


